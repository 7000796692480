<template>
  <section class="part mb-4 relative">
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div class="text-container col-12 md:col-6 md:offset-1">
          <section v-html="payload.text" />
        </div>
      </div>
      <div class="row-12">
        <div class="col-12 md:col-3 md:offset-1 image-wrapper">
          <Image class="image" :src="payload.size1" />
          <div class="text" v-html="payload.col1" />
        </div>
        <div class="col-12 md:col-3 image-wrapper">
          <Image class="image pic-2" :src="payload.size2" />
          <div class="text" v-html="payload.col2" />
        </div>
        <div class="col-12 md:col-3 image-wrapper">
          <Image class="image pic-3" :src="payload.size3" />
          <div class="text" v-html="payload.col3" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.image-wrapper {
  position: relative;
  @apply flex flex-col;
}

.text-container {
}

.pic-down {
  top: px(480);
}

.pic-2 {
  height: px(230);
  width: fit-content;
}

.pic-3 {
  height: px(180);
  width: fit-content;
}

.image {
  @apply mb-6 mt-auto;
  @screen md {
    margin-left: -35px;
  }
}

.content {
}

.part {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pic2 {
}

.text {
  :deep(strong) {
    color: var(--color-gray-blue);
  }
}
</style>
